import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IdentityModel } from 'src/app/models/identity.model';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private url = 'https://valida.c0v.co/api/Validador/servicios/acceso';
  //private url = 'http://192.168.87.215:8084/servicios-sagrilaft/servicios/acceso';
  //private url = '/servicios/acceso';

  public DatosUsuario: IdentityModel = new IdentityModel();

  userToken: any;
  visible: boolean;

  constructor( private http: HttpClient ) {
    this.visible = false;
    this.ReadToken();
  }

  // Login

  login( usuario: any  ) {
    this.IsAutenticated();
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    usuario.contrasena=encodeURIComponent(usuario.contrasena);
    const options = {
      params: usuario, headers
    };


    return this.http.get(
     this.url , options
    ).pipe(
      map( resp => {
        this.saveToken( resp['TOKEN'] );
        return resp;
      })
    );

  }

   // Guardar Token
  private saveToken( token: string ) {

    this.userToken = token;
    localStorage.setItem('tokenapp1', token);
  }

  // Leer Token
  ReadToken() {

    if ( localStorage.getItem('tokenapp1') ) {
      this.userToken = localStorage.getItem('tokenapp1');
    } else {
      this.userToken = '';
    }
    return this.userToken;
  }

// Logout
logout() {
  localStorage.removeItem('tokenapp1');
  localStorage.removeItem('identityapp1');
}


// Get identity
GetIdentity() {
 return this.DatosUsuario = JSON.parse(localStorage.getItem('identityapp1'));
}

// Validar Autenticación
IsAutenticated(): boolean {
return this.userToken;
}

// Barra de navegación
hide() { this.visible = false; }
show() { this.visible = true; }
toggle() { this.visible = !this.visible; }


}
