import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Globals } from 'src/app/globals/global';
import { ToastrService } from 'ngx-toastr';
import { $ } from 'protractor';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  providers: [Globals],
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

usuario: any;
Swal: any;
DatosUsuario: any;
FormLogin: FormGroup;
public year: number = Date.now();

  public token: string;
  public title: string;
  public status: string;

  public identity: object = {
    NOMBRE: '',
  };

  ObjLogin = {
    USUARIO: '',
    CONTRASENA: ''
  };


  constructor( private auth: AuthService,
               private router: Router,
               private globals: Globals,
               private toastr: ToastrService ) {

                this.FormLogin = new FormGroup({
                  USUARIO: new FormControl('',  [Validators.required, Validators.minLength(8)]),
                  CONTRASENA: new FormControl('', [Validators.required, Validators.minLength(4)]),
                });

                this.FormLogin.setValue( this.ObjLogin );

    }

  ngOnInit() {
    this.auth.hide();

  // Login
    if ( localStorage.getItem('usuario') ) {
      this.usuario.usuario = localStorage.getItem('usuario');
    }
  }

  Login( form: NgForm ) {

    if (form.invalid) { return; }
    this.auth.login( this.FormLogin.value )
      .subscribe( (resp: any) => {
        this.identity = resp;
       // Persistir Datos
        localStorage.setItem('identityapp1', JSON.stringify(resp));
        this.DatosUsuario = JSON.parse(localStorage.getItem('identityapp1'));

        if ( this.DatosUsuario != null ) {
          this.ToastNotification('', '¡Bienvendid@ ' + this.DatosUsuario.NOMBRE + '!');
         }

        this.router.navigateByUrl('/validador');
      }, (err) => {
         console.log('error en login', err.error);

        this.toastr.error( err.error.replace("Error","Error, "), "", {
          timeOut: 10000,
          enableHtml :  true
        });
      });
  }

    // Mensajes Toast
  ToastNotification(titulo: string, descripcion: string) {
   this.toastr.success(titulo, descripcion);
  }

}
