// globals.ts
import { Injectable } from '@angular/core';

@Injectable()
export class Globals {
  public year: number = Date.now();
  public identity: any;
  constructor() {
    if (localStorage.getItem('identityapp1') !== null) {
      this.identity = JSON.parse(localStorage.getItem('identityapp1'));
    }
  }
}
