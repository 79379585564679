import { Component, OnInit, DoCheck, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
declare var particlesJS: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, DoCheck {
  title = 'appValidador';
  public toggleNavbar = false;

  constructor(private router: Router) {
  }

  ngOnInit() {
    }

    ngDoCheck() {
      if (this.router.url == '/login') {
        this.toggleNavbar = false;
      } else {
        this.toggleNavbar = true;
      }
    }
}
