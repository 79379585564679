import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/user/login/login.component';
import { AuthGuard } from './guards/auth.guard';
import { UploadFileComponent } from './components/validador/validador.component';



const APP_ROUTES: Routes = [

    { path: 'login', component: LoginComponent },
    { path: 'validador', component: UploadFileComponent, canActivate: [AuthGuard] },
    { path: '**', pathMatch: 'full', redirectTo:  'login' },
];

export const APP_ROUTING = RouterModule.forRoot(APP_ROUTES, { useHash: true });
