import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,  } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SubirArchivoService  {
   Headers: any;
   identity: any;
   //urlApi = 'http://localhost:8080/Validador/servicios';
   urlApi = 'https://valida.c0v.co/api/Validador/servicios';
   constructor( private http: HttpClient) {
    this.Headers = new HttpHeaders();
    this.Headers.append('Content-Type', 'multipart/form-data');
    this.Headers.append('Accept', 'application/json');
    this.Headers = this.Headers.set('token', '8bf21b6f93822df8c5e5d1bd2f409deb');
    this.identity = JSON.parse(localStorage.getItem('identityapp1'));
  }



   
   Servicio(servicio:String,datos?: any) 
   {
    const headers = this.Headers;
    const options = { params: datos, headers };
    const formData: FormData = new FormData();
    return this.http.post(`${this.urlApi}/pr_${servicio}`, formData, {headers: options.headers,
      params: options.params, responseType: 'json'});
   }
}

