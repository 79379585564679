import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {
 public Headers: any;

  constructor() {
    this.Headers = new HttpHeaders();
    this.Headers = this.Headers.set('Content-Type', 'application/json');
    this.Headers = this.Headers.set('token', localStorage.getItem('token'));
   }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headers = this.Headers;

    const reqClone = req.clone({
      headers
    });

    return next.handle(req);
  }
}
