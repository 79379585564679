import { Component, OnInit } from '@angular/core';
import { Globals } from 'src/app/globals/global';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  providers: [ Globals ],
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  public nombre: any;

  constructor(public globals: Globals,
              public auth: AuthService,
              private router: Router) { }

  ngOnInit() {
    if (localStorage.getItem('identityapp1') !== null) {
      this.nombre  = this.globals.identity.NOMBRE;
    }
  }

  CerrarSesion() {
   this.auth.logout();
   localStorage.clear();
   this.router.navigateByUrl('login');
  }

}
