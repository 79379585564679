import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm, FormGroup } from '@angular/forms';
import { Globals } from 'src/app/globals/global';
import { SubirArchivoService } from 'src/app/services/subir-archivo.service';
import { ToastrService } from 'ngx-toastr';
import Swal, { SweetAlertType } from 'sweetalert2';

import * as FileSaver from 'file-saver';
import * as XLS from 'xlsx';
import { AuthService } from 'src/app/services/auth.service';
import { forEach } from '@angular/router/src/utils/collection';
//import { userInfo } from 'os';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xls';
declare let $: any;

@Component({
  selector: 'app-validador',
  templateUrl: './validador.component.html',
  providers: [ Globals ],
  styleUrls: ['./validador.component.scss']
})
export class UploadFileComponent implements OnInit {
  public year = this.globals.year;

  // File input
  PREGUNTAS_VALIDACION: any= {
    MOSTRAR:false,
    GENERAL:{datos:[]},
    LUGAR_EXPEDICION:"",
    FECHA_EXPEDICION:"",
    
    /*,
    TELEFONOS: [
      {
        TEXTO: "52***42",
        ULTIMO_REPORTE: "2021-10-08",
        CIUDAD: "BOGOTA ( BOGOTA DISTRITO CA )",
        VALOR: "5202242"
      },
      {
        TEXTO: "33***00",
        ULTIMO_REPORTE: "2021-08-31",
        CIUDAD: "CALI ( VALLE )",
        VALOR: "3330000"
      },
      {
        TEXTO: "74***23",
        ULTIMO_REPORTE: "2021-08-31",
        CIUDAD: "CALI ( VALLE )",
        VALOR: "7452323"
      },
      {
        TEXTO: "61***58",
        ULTIMO_REPORTE: "2020-08-31",
        CIUDAD: "BOGOTA ( BOGOTA DISTRITO CA )",
        VALOR: "6195858"
      },
      {
        TEXTO: "92***71",
        ULTIMO_REPORTE: "2019-06-30",
        CIUDAD: "BOGOTA ( BOGOTA DISTRITO CA )",
        VALOR: "9239671"
      }
    ],
    DIRECCIONES: [
      {
        TEXTO: "CL 50 # 8*********",
        ULTIMO_REPORTE: "2021-05-31",
        CIUDAD: "CALI ( VALLE )",
        VALOR: "CL 50 # 86 - 56 TO 4 AP 103 UND RESIDEN"
      },
      {
        TEXTO: "CL 9 # 46*********",
        ULTIMO_REPORTE: "2021-05-31",
        CIUDAD: "CALI ( VALLE )",
        VALOR: "CL 9 # 46 - 69 OF 107"
      },
      {
        TEXTO: "CL 50 � #*********",
        ULTIMO_REPORTE: "2021-01-31",
        CIUDAD: "CALI ( VALLE )",
        VALOR: "CL 50 � # 86 - 56"
      },
      {
        TEXTO: "KR 50 # 1*********",
        ULTIMO_REPORTE: "2019-07-23",
        CIUDAD: "BOGOTA ( BOGOTA DISTRITO CA )",
        VALOR: "KR 50 # 104 B - 34 QAP 529 ED ACUARELA"
      },
      {
        TEXTO: "KR 16 # 9*********",
        ULTIMO_REPORTE: "2019-06-30",
        CIUDAD: "BOGOTA ( BOGOTA DISTRITO CA )",
        VALOR: "KR 16 # 94 A - 23 AP 103"
      }
    ],
    CORREOS: [
      {
        TEXTO: "DIF****@GMAIL.COM",
        ULTIMO_REPORTE: "2021-10-08",
        VALOR: "DIFERGO06@GMAIL.COM"
      },
      {
        TEXTO: "DIF****@HOTMAIL.COM",
        ULTIMO_REPORTE: "2016-10-30",
        VALOR: "DIFERGO06@HOTMAIL.COM"
      },
      {
        TEXTO: "DIE****@DIRECTVLA.COM.CO",
        ULTIMO_REPORTE: "2015-06-16",
        VALOR: "DIEGOM@DIRECTVLA.COM.CO"
      }
    ],
    GENERAL: {
      datos: [
        {
          PSN_ID: "32",
          FECHA: "2021-10-12",
          LUGAR_EXPEDICION_DOC: "CALI",
          FECHA_EXPEDICION_DOC: "1998-08-06"
        }
      ]
    },
    CELULARES: [
      {
        TEXTO: "30****0499",
        ULTIMO_REPORTE: "2021-10-08",
        VALOR: "3004993034"
      },
      {
        TEXTO: "31****1379",
        ULTIMO_REPORTE: "2009-05-31",
        VALOR: "3113791562"
      },
      {
        TEXTO: "31****1379",
        ULTIMO_REPORTE: "2009-05-31",
        VALOR: "3113792777"
      }
    ]*/
  };
  n_preguntas:number=0;
  cedula: any={fecha_expedicion:"1999-06-19",lugar_expedicion:""};
  res: any={};
  formImport: FormGroup;
  archivoSubir: File;
  files: File[] = [];
  resultado_busqueda: any={TELEFONOS:{datos:[]},CORREOS:{datos:[]}};
  TITULOS_TABLA: any=[];
  filas: any;
  tipo_documento: string;
  documento: string;
  contacto_selecionado: string[]=[''];
  otp: any={Codigo:"",Error:false,Mensaje:"",CONTACTO_EN_CIFIN:false};
  form_EnviarOTP: any;
  enviar_a: any;
  public word: string;
  public xml: string;
  public pdf: string;
  public png: string;
  public jpg: string;
  public xlsx: string;


  // Propiedades
  public datos: any = {};
  public MENSAJE = false;
  @ViewChild('formEnviar') formEnviar: NgForm;
  @ViewChild ('inputFileValue') inputFileValue: any;
  public excel = [];
  

  constructor(public globals: Globals,
              private toastr: ToastrService,
              private subirArchivoService: SubirArchivoService,
               ) {
                this.word = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
                this.xml = 'text/xml';
                this.pdf = 'application/pdf';
                this.png = 'image/png';
                this.jpg = 'image/jpeg';
                this.xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
               }

  ngOnInit() {
      this.PREGUNTAS_VALIDACION=this.GenerarPreguntasAletoreas(this.PREGUNTAS_VALIDACION);
   }

  remove(i: any) {
    this.files.splice(i, 1);
    if (this.files.length <= 0) {
      this.RemoveAll();
    }
  }

  RemoveAll() {
    this.inputFileValue.nativeElement.value = '';
    this.archivoSubir = null;
    this.files = [];
  }
  
  private NumeroAletorio(min, max) 
  {
    return Math.floor(Math.random() * (max - min)) + min;
  }

  public generaCadenaAleatoria(n: number,SoloNumeros:boolean,TextoOriginal?:string): string 
  {
    
    let result = '';
    let chars = SoloNumeros?'0123456789':'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    for (let i = 0; i < n; i++){
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    if(TextoOriginal && TextoOriginal ==result)
      return this.generaCadenaAleatoria(n,SoloNumeros,TextoOriginal);
    return result;
  }

  GenerarPreguntasAletoreas(PREGUNTAS_VALIDACION: JSON)
  {
    this.n_preguntas = 0;
    let JOret:any={"MOSTRAR":true,"GENERAL":PREGUNTAS_VALIDACION["GENERAL"].datos[0]};
    for(let Grupo in PREGUNTAS_VALIDACION)
    {
      if(Grupo !="GENERAL" && PREGUNTAS_VALIDACION[Grupo].length > 0)
      {
        this.n_preguntas++;
        //JOret{Grupo:[]};
        JOret[Grupo]=[PREGUNTAS_VALIDACION[Grupo][0]];
        JOret[Grupo][0].VALOR=1;
        let TextoOri=JOret[Grupo][0].TEXTO;
        let nCaracteres=TextoOri.length;
        JOret[Grupo][0].TEXTO="*"+JOret[Grupo][0].TEXTO;
        for(let i:number=1;i<4;i++)
        {
          if(Grupo =="CELULARES")
            JOret[Grupo][i] = { TEXTO:"3"+ this.NumeroAletorio(0,3)+"****"+this.generaCadenaAleatoria(4,true,TextoOri.substring(-4)),VALOR:0};
          if(Grupo =="TELEFONOS")
            JOret[Grupo][i] = {TEXTO:TextoOri.charAt(0)+this.generaCadenaAleatoria(1,true)+TextoOri.substring(2,nCaracteres-2)+this.generaCadenaAleatoria(2,true,TextoOri.substring(-2)),VALOR:0};
          if(Grupo =="DIRECCIONES")
            JOret[Grupo][i] = {TEXTO:(this.NumeroAletorio(0,2)==0?"CL ":"CR ")+this.generaCadenaAleatoria(2,true)+" #"+this.generaCadenaAleatoria(1,true)+TextoOri.substring(9),VALOR:0};
          if(Grupo =="CORREOS")
            JOret[Grupo][i] = {TEXTO:this.generaCadenaAleatoria(3,false,TextoOri.substring(0,3))+TextoOri.substring(3),VALOR:0};
        }
        JOret[Grupo].sort(function() { return Math.random() - 0.5 });
      }
    };
    if(this.n_preguntas < 2)
      JOret.MOSTRAR = false;

    return JOret;
  }
  
  ValidarPreguntas()
  {
    
    if($("#formPreguntas small.form-text.text-danger:visible").length > 0)
    {
      Swal.fire
      ({
        allowOutsideClick: false,  
        type: "error",
        title:"Error",
        html:'Todas las preguntas son obligatorias, revise los campos marcados con rojo',
        showConfirmButton: true
      });      
      return false;
    }
    let suma:number=0;
    if(this.cedula.fecha_expedicion == this.PREGUNTAS_VALIDACION.GENERAL.FECHA_EXPEDICION_DOC)
      suma++;
      console.log(this.res);
    for(let campo in this.res)
      suma+=Number.parseInt(this.res[campo]);












    let calificacion = (suma/(this.n_preguntas+1));  
    let puntaje_minimo = .8;

    if(calificacion >= puntaje_minimo)
    {
      this.EnviarCalificacion(calificacion,puntaje_minimo);
      this.PopupSuministrarInfo();
    }
    else
    {
      this.EnviarCalificacion(calificacion,puntaje_minimo);
      this.PREGUNTAS_VALIDACION = {MOSTRAR:false};
      this.documento = "";

      Swal.fire
      ({
        position: 'center',
        type: 'error',
        text:'El proceso de validación no fue exitoso, una o varias respuestas incorrectas.',
        title: 'Validación de identidad fallida',
        showConfirmButton: true
      })      
      .then((result) => 
      {
        window.location.reload();
      });      
    }
  }

  PopupValidarCodigo(Codigo: String,Mensaje: String, TipoPopup :SweetAlertType,Otp: any )
  {
    Swal.fire
    ({
      allowOutsideClick: false,  
      type: TipoPopup,
      title:Mensaje,
      html:'<div class=" d-flex justify-content-center"><div class="col-md-6"><input [(ngModel)]="Codigo" id="Codigo"/></div></div>',
      showConfirmButton: true
    })
    .then((result) => 
    {
      if($("#Codigo").val().toLowerCase() == Otp.Codigo.toLowerCase())
      {
        if(Otp.tipo != "contacto_nuevo"  || (Otp.tipo=="contacto_nuevo" && Otp.CONTACTO_EN_CIFIN))
        {
          this.PopupSuministrarInfo();
        }
        else
          this.MostrarPreguntasValidacion(Otp);
      }
      else//Codigo OTP Invalido
        this.PopupValidarCodigo(Codigo,Mensaje,'error',Otp);
    });    
  }
  PopupSuministrarInfo() 
  {
    Swal.fire({
      position: 'center',
      type: 'success',
      text:'Suministrar información',
      title: 'Validación de identidad exitosa',
      showConfirmButton: true
    })
    .then((result) => 
    {
      window.location.reload();
    });      
;
  }

  BuscarOTP(tipo_documento: String, documento:String)
  {
    this.MENSAJE=true;
    this.datos=JSON.parse(localStorage.getItem('identityapp1'));
    this.datos.documento = documento!=undefined?documento:"";
    this.datos.tipo_documento = tipo_documento!=undefined?tipo_documento:"";

    Swal.fire({
      allowOutsideClick: false,
      type: 'info',
      text: 'Espere por favor...',
      showConfirmButton: false,
    });
    Swal.showLoading();
    this.subirArchivoService.Servicio("listado_telefonos",this.datos)
    .subscribe((resp: any) => 
    {

        this.resultado_busqueda=resp;
        
        $('#modalUpFile').modal('hide');
        Swal.fire({
          position: 'center',
          type: 'success',
          title: '',
          showConfirmButton: true,
          timer: 1000
        });
        Swal.showLoading();
    }, (err: any) => {
      this.inputFileValue.nativeElement.value = '';
      this.archivoSubir = undefined;
      this.toastr.error( 'Error de comunicación Err02', null, {
        timeOut: 1000
      });
      Swal.close();
    });
    return false;
  }

  ServicioEnvioOtP(Form: String)
  {
    let Parametros = $(Form).serializeArray().map(function(x){this[x.name] = x.value; return this;}.bind({}))[0];
    this.datos=JSON.parse(localStorage.getItem('identityapp1'));
    Parametros.TOKEN = this.datos.TOKEN;
    Parametros.USUARIO = this.datos.USUARIO;
    Parametros.NOMBRE = this.datos.NOMBRE;

    console.dir(Parametros);
    Swal.fire({
      allowOutsideClick: false,
      type: 'info',
      text: 'Espere por favor...',
      showConfirmButton: false,
    });
    Swal.showLoading();
      this.subirArchivoService.Servicio("enviar_otp",Parametros )
      .subscribe((resp: any) => 
      {
        console.dir(resp);
          this.otp=resp;
          if(this.otp.Error)
          {
              Swal.fire({
                position: 'center',
                type: 'error',
                text:this.otp.Mensaje,
                title: 'Error',
                showConfirmButton: true
              });
          }
          else
            this.PopupValidarCodigo(this.otp.Codigo,'Ingrese el código recibido','info',this.otp);
      }, (err: any) => 
      {
        this.inputFileValue.nativeElement.value = '';
        this.archivoSubir = undefined;
        this.toastr.error( '¡Error de comunicación con el servidor!', null, {
          timeOut: 10000
        });
        Swal.close();
      });
  }
  
  EnviarCalificacion(calificacion: number, puntaje_minimo: number) {
    this.MENSAJE=true;
    this.datos=JSON.parse(localStorage.getItem('identityapp1'));
    this.datos.calificacion = calificacion;
    this.datos.puntaje_minimo = puntaje_minimo;
    
/*    Swal.fire({
      allowOutsideClick: false,
      type: 'info',
      text: 'Espere por favor...',
      showConfirmButton: false,
    });
    Swal.showLoading();
  */
    this.subirArchivoService.Servicio("puntaje",this.datos)
    .subscribe((resp: any) => 
    {
  
        this.resultado_busqueda=resp;
        
    /*    $('#modalUpFile').modal('hide');
        Swal.fire({
          position: 'center',
          type: 'success',
          title: '',
          showConfirmButton: true,
          timer: 1000
        });
        Swal.showLoading();*/
    }, (err: any) => {
      this.inputFileValue.nativeElement.value = '';
      this.archivoSubir = undefined;
      this.toastr.error( 'Error de comunicación Err02', null, {
        timeOut: 1000
      });
      //Swal.close();
    });
    return false;
  }
  
  
  
  SolicitarContacto(dato: string)
  {
    Swal.fire
    ({
      allowOutsideClick: false,  
      type: 'question',
      title: 'Digite el correo o celular para enviar el código',
      html:`
          <form id="form_EnviarNuevoOTP">
            <div class="mdc-text-field">
              <p>
                <input matInput placeholder="Correo/Celular"  [(ngModel)]="enviar_a" name="enviar_a" value="`+dato+`">
              </p>
              <p>
                <input type='hidden' name="tipo" value='contacto_nuevo'>
                <input type='hidden'  name="tipo_documento" value="`+this.tipo_documento+`"/>
                <input type='hidden'  name="documento"      value="`+this.documento+`"/>

              </p>
            </div>
          </form>`
          ,
      showConfirmButton: true
    })
    .then((result) => 
    {
      let dato_digitado = $("#form_EnviarNuevoOTP [name='enviar_a']").val();
      console.log(this.resultado_busqueda);
      let contacto_propio = "contacto_nuevo";
      let buscar_en = this.resultado_busqueda.TELEFONOS.datos;
      if(this.esEmailValido(dato_digitado))
        buscar_en = this.resultado_busqueda.CORREOS.datos;
      else if(!this.esCelularValido(dato_digitado))
      {
        this.SolicitarContacto(dato_digitado);
        this.toastr.error( dato_digitado+' no es un correo ni un celular valido.', null, {
          timeOut: 20000
        });
        return true;
      }

      buscar_en.forEach(DATO => 
      {
          if(dato_digitado.toUpperCase().trim() == DATO.ID_FILA.toUpperCase().trim() && this.documento != '80121504')
            contacto_propio = "contacto_propio";
      });

      $("#form_EnviarNuevoOTP [name='tipo']").val(contacto_propio);
      this.ServicioEnvioOtP('#form_EnviarNuevoOTP');
    });  
  }

  esEmailValido(email: string):boolean 
  {
    let mailValido = false;
      'use strict';

      var EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

      if (email.match(EMAIL_REGEX)){
        mailValido = true;
      }
    return mailValido;
  }

  esCelularValido(dato: string):boolean 
  {
      'use strict';
      return (dato.match(/^3[0-9]{9}$/)?true:false);
  }

  EnviarOTP(correo: any, celular:String)
  {
    
    Swal.fire({
      allowOutsideClick: false,  
      type: 'info',
      text: 'Espere por favor...',
      showConfirmButton: false,
    });
    Swal.showLoading();
    if(this.contacto_selecionado[0]!="-1" || this.contacto_selecionado[1]!="-1")
      this.ServicioEnvioOtP('#form_EnviarOTP');
    else
      this.SolicitarContacto("");

    return false;
  }


  private saveAsExcelFile(buffer: any, fileName: string): void {
     const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
     FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
     setTimeout(() => {
       Swal.close();
       this.inputFileValue.nativeElement.value = '';
     }, 3000);
  }

  private MostrarPreguntasValidacion(Otp: any) 
  {
    this.PREGUNTAS_VALIDACION=this.GenerarPreguntasAletoreas(Otp.PREGUNTAS_VALIDACION);
  }
}


