import { Component, OnInit } from '@angular/core';
import { Globals } from 'src/app/globals/global';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  providers: [ Globals ],
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public year: any;

  constructor(private globals: Globals) { }

  ngOnInit() {
    this.year = this.globals.year;
  }

}
